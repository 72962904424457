.nav-text {
    display: flex;
    /* justify-content: start; */
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: #1a1a1a;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-text a:hover {
    color: #000000;
}
